import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import Header from "../components/header"
import Container from "../components/container"
import AnimationWrapper from "../components/animationWrapper"
import TextSection from "../components/textSection"
import ContactSection from "../components/contactSection"
import Layout from "../components/layout"


const PrivacyPolicyPage = ( {data, location} ) => {

  return (

    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      <AnimationWrapper>
        <Header pageName="Polityka prywatności" data={data.header} />
      </AnimationWrapper>

      {data.page.frontmatter.text_section &&
        <section id="klauzula-informacyjna" className="page-section">
          <Container>
            <AnimationWrapper className="description-small">
               <TextSection className="section-description listing-description align-left" data={data.page.frontmatter.text_section} />
            </AnimationWrapper>
          </Container>
        </section>
      }


      <section id="contact-data" className="page-section">
        <ContactSection data={data.page.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "polityka-prywatnosci" }, type: { eq: "page" } }) {
      frontmatter{
        title
        text_section{
          title
          title_visible
          align
          subtitle
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          button_text
          button_url
          button_style
          text
        }
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    header: file(relativePath: {eq: "page-headers/header-ofirmie.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 95
          transformOptions: {fit: COVER}
          webpOptions: {quality: 95}
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default PrivacyPolicyPage
